import React, { Component } from 'react';
import './App.css';

import Menu from './components/menu/menu';
import About from './components/about/about';
import Work from './components/work/work';
import Contact from './components/contact/contact';

import logo from './assets/logo/logo.jpg';
import linkedin from './assets/logos/linkedin.svg';
import twitter from './assets/logos/twitter.svg';
import facebook from './assets/logos/facebook.svg';
import instagram from './assets/logos/instagram.svg';

class App extends Component {
    
    constructor(props) {
        super(props);
        this.params = {
            overlay: null,
            mc: null,
            ac: null,
            fc: null,
            socialBtns: null,
            upperTitle: null,
            menuBtn: null
        };
        this.state = {
            menu: false,
            about: false,
            contact: false,
            work: false
        }
    }

    componentDidMount() {
        this.init();
        setTimeout(() => {
            this.params.overlay.classList.add('fadeMeOut');
            this.params.ac.classList.add('animateZoomedIn');
            setTimeout(() => {
                this.params.socialBtns.classList.add('animateTranslateIn');
                this.params.upperTitle.classList.add('loadTitle');
                this.params.menuBtn.classList.add('loadMenuButton');
            }, 200);
        }, 100);
    }

    init() {
        this.params.overlay = document.getElementById('overlay');
        this.params.mc = document.getElementById('main-container'); 
        this.params.ac = document.getElementById('animated-container');
        this.params.fc = document.getElementById('fixed-container');
        this.params.socialBtns = document.getElementById('social-logos');
        this.params.upperTitle = document.getElementById('upper-title');
        this.params.menuBtn = document.getElementById('menu-btn');
    }

    animatedContainerAnimationEndEventHandler() {
        this.params.ac.classList.add('doneLoading');
        this.params.fc.classList.add('medZIndex');
        setTimeout(() => {
            this.params.ac.classList.remove('animateZoomedIn');
            this.params.overlay.parentNode.removeChild(this.params.overlay);
        }, 1000);
    }

    // Menu functions
    menuMouseEnterEvent() {
        this.params.ac.classList.add('animated-container-mleft');
    }

    menuMouseLeaveEvent() {
        this.params.ac.classList.remove('animated-container-mleft');
    }

    menuMouseDownEvent() {
        if (!this.state.menu) this.setState({ menu: true, about: false, work: false, contact: false });
        else this.setState({ menu: false, about: false, work: false, contact: false });
    }

    getMode() {
        let w = window.innerWidth;
        if (w <= 823) return 'mobile';
        return 'pc';
    }
    
    openSection(type) {
        let smenu = this.getMode() === 'mobile' ? false:true;
        // eslint-disable-next-line default-case
        switch (type) {
            case 'about': return this.setState({ menu: smenu, about: true, work: false, contact: false });
            case 'work': return this.setState({ menu: smenu, about: false, work: true, contact: false });
            case 'contact': return this.setState({ menu: smenu, about: false, work: false, contact: true });
        }
    }

    render() {
        let menuOnClass = ``;
        if (this.state.menu || this.state.about || this.state.work || this.state.contact) menuOnClass += 'menuOn';
        const menu = this.state.menu ? <Menu clickEv={this.openSection.bind(this)}></Menu> : <div style={{display: 'none'}}></div>;
        const subMenuItem = this.state.about ?
           <About></About> : this.state.work ?
           <Work></Work>   : this.state.contact ? <Contact></Contact> : <div style={{display: 'none'}}></div>;

        return (
            <div className='app'>
            <div id='main-container' className={menuOnClass}>
                <p id="upper-title">Ashraf Gabesh</p>
                <div id="animated-container" onAnimationEnd={this.animatedContainerAnimationEndEventHandler.bind(this)}></div>
                <div id="fixed-container"></div>
                <div id="logo-container">
                    <img id="logo" src={logo} alt="G Software"/>
                </div>
                <div id="social-logos">
                    <img src={linkedin} alt="linkedin" className="logo" onClick={() => {window.open('https://www.linkedin.com/in/ashraf-gabesh-2b9364b4/' ,'_blank')}} id="linkedin"/>
                    <img src={twitter} alt="twitter" className="logo" onClick={() => {window.open('https://twitter.com/ashgb2' ,'_blank')}} id="twitter"/>
                    <img src={facebook} alt="facebook" className="logo" onClick={() => {window.open('https://www.facebook.com/people/Ashraf-Gabesh/100009167369073' ,'_blank')}} id="facebook"/>
                    <img src={instagram} alt="instagram" className="logo" onClick={() => {window.open('https://www.instagram.com/ashrafgabesh/' ,'_blank')}} id="instagram"/>
                </div>
                <div id="menu-btn"
                    onMouseEnter={() => this.menuMouseEnterEvent.bind(this)}
                    onMouseLeave={() => this.menuMouseLeaveEvent.bind(this)}
                    onMouseDown={this.menuMouseDownEvent.bind(this)}>
                    <div className="lines">
                        <div className="line l1"></div>
                        <div className="line l2"></div>
                        <div className="line l3"></div>
                    </div>
                    <div className="menutext"><small>MENU</small></div>
                    <div className="closetext"><small>CLOSE</small></div>
                </div>
                {menu}
                {subMenuItem}
            </div>
            </div>
        );
    }
}

export default App;
