import React from 'react';
import './about.css';

const About = () => {
    return (
        <div id="about">
            <p className="title">Hi, I'm Ashraf Gabesh</p>
            <p className="subtitle">I'm a full-stack software developer</p>
            <p className="maintext">Talented Software Engineer with over five years of experience. Solid understanding of full software development life cycles and user experience. Dedicated to continuously developing, implementing, testing and adopting new technologies to maximize development and testing efficiency and produce innovative applications.</p>
            <p className="maintext">Worked as a software developer for big companies and for startups and now I run my own software development business located in Israel</p>
            <p className="stack">My current stack includes:</p>
            <ul className="technologies">
                <li>NodeJS</li>
                <li>AngularJS 2+</li>
                <li>React.js</li>
                <li>Redis</li>
                <li>MongoDB</li>
                <li>MYSQL</li>
                <li>AWS</li>
            </ul>
        </div>
    );
}

export default About;