import React from 'react';
import './work.css';

const Work = () => {
    return (
        <div id="work">
            <p className="title">Some of the projects I am working on or worked on as a freelancer</p>
            <div className="section">
                <div className="header">
                    <p className="title">Token payments Inc.</p>
                    <small className="subtitle">New York, USA</small>
                </div>
                <div className="body">
                    <p>Token payments is a startup located in New York, USA. They developed a virtual, secure, anti-fraud payment cards. During the last year I have been working with them on the web side, developed the startup <a rel="noopener noreferrer" href="https://jointoken.com" target="_blank">website</a>, CRM system, Google chrome extension and more tools and stuff used in the server side.</p>
                    <a rel="noopener noreferrer" href="https://jointoken.com">Jointoken.com</a>
                </div>
            </div>
            <div className="section">
                <div className="header">
                    <p className="title">Jamaica pet grooming services</p>
                    <small className="subtitle">Yarka, Israel</small>
                </div>
                <div className="body">
                    <p>Jamaica is a pet grooming service, It's located in Yarka, Israel. I have developed for them a mobile (Ios & Android) application, It contains social network registration, appointment management and more.</p>
                    <a rel="noopener noreferrer" href="https://itunes.apple.com/il/app/jamaica-%D7%9E%D7%A1%D7%A4%D7%A8%D7%AA-%D7%9B%D7%9C%D7%91%D7%99%D7%9D-%D7%99%D7%A8%D7%9B%D7%90/id1447873338?mt=8" target="_blank">Jamaica for IOS</a>
                    <br/>
                    <a rel="noopener noreferrer" href="https://play.google.com/store/apps/details?id=com.ashraf.jamaica" target="_blank">Jamaica for Android</a>
                </div>
            </div>
            <div className="section">
                <div className="header">
                    <p className="title">You Think You Know JS!</p>
                    <small className="subtitle">Web app</small>
                </div>
                <div className="body">
                    <p>You think you know JS is a web application that centralizes interviews advanced javascript questions, You can play with to test or refresh your JS knowledge. developed it using React.js library. feel free to play with it and have fun!</p>
                    <a rel="noopener noreferrer" href="https://js.ashrafgabesh.com" target="_blank">You think you know JS!</a>
                </div>
            </div>
            <div className="section">
                <div className="header">
                    <p className="title">Marwan Adv.</p>
                    <small className="subtitle">Yarka, Israel</small>
                </div>
                <div className="body">
                    <p>Marwan Adv. is a law firm located in Yarka, Israel. I have developed their website using Angular JS 2+ Technology.</p>
                    <a rel="noopener noreferrer" href="https://marwanadv.com" target="_blank">marwanadv.com</a>
                </div>
            </div>
            <p className="footer">To see my experience as an employee you can take a look at my <a rel="noopener noreferrer" href="https://www.linkedin.com/in/ashraf-gabesh-2b9364b4/" target="_blank">Linkedin</a> profile.</p>
        </div>
    );
}

export default Work;