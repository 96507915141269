import React from 'react';
import './contact.css';

const Contact = () => {
    return (
        <div id="contact">
            <p className="title">Feel free to contact me on any of these ways:</p>
            <ul>
                <li>
                    <div className="text">+972523201837</div>
               </li>
               <li>
                   <div className="text">
                        <a rel="noopener noreferrer" href="mailto:ash.gb2@gmail.com">ash.gb2@gmail.com</a>
                   </div>
               </li>
               <li>
                   <div className="text"><a rel="noopener noreferrer" href="https://www.linkedin.com/in/ashraf-gabesh-2b9364b4/" target="_blank">Linkedin</a></div>
               </li>
               <li>
                   <div className="text"><a rel="noopener noreferrer" href="https://twitter.com/ashgb2" target="_blank">Twitter</a></div>
               </li>
               <li>
                   <div className="text"><a rel="noopener noreferrer" href="https://www.facebook.com/people/Ashraf-Gabesh/100009167369073" target="_blank">Facebook</a></div>
               </li>
               <li>
                   <div className="text"><a rel="noopener noreferrer" href="https://www.instagram.com/ashrafgabesh/" target="_blank">Instagram</a></div>
               </li>
            </ul>
        </div>
    );
}

export default Contact;