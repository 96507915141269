import React, {Component} from 'react';
import './menu.css';

class Menu extends Component {
    
    constructor(props) {
        super (props);
        this.state = {
            leftV: this.getSubContentLeftValue()
        };
    }

    getMode() {
        let w = window.innerWidth;
        if (w <= 823) return 'mobile';
        return 'pc';
    }
    getSubContentLeftValue() {
        switch (this.getMode()) {
            case 'pc': return 230;
            case 'mobile': return 120;
            default: return 
        }
    }

    updateDimensions() {
        this.setState({
            leftV: this.getSubContentLeftValue()
        });
    }
    componentWillMount() {
        this.updateDimensions();
    }
    componentDidMount() {
        window.addEventListener("resize", this.updateDimensions.bind(this));
    }
    componentWillUnmount() {
        window.removeEventListener("resize", this.updateDimensions.bind(this));
    }

    render() {
        return (
            <div id="subcontent" style={{left: this.getSubContentLeftValue()}} className="animated bounceInLeft fast">
                <h1>Menu</h1>
                <ul>
                    <li id="aboutbtn" onClick={() => { this.props.clickEv('about'); }}><h2>About</h2><div className="underline ul1"></div></li>
                    <li id="workbtn" onClick={() => { this.props.clickEv('work'); }}><h2>Work</h2><div className="underline ul2"></div></li>
                    <li id="contactbtn" onClick={() => { this.props.clickEv('contact'); }}><h2>Contact</h2><div className="underline ul3"></div></li>
                </ul>
                <div className="pinfo">
                    <h5 className="name">Ashraf Gabesh</h5>
                    <p className="pos">Full stack developer</p>
                    <p className="mnumber">+972-523201837</p>
                    <p className="mail"><a href="mailto:ash.gb2@gmail.com">ash.gb2@gmail.com</a></p>
                </div>
            </div>
        );
    }
}

export default Menu;